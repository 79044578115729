/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    code: "code",
    ul: "ul",
    li: "li",
    strong: "strong",
    h3: "h3",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "."
  }, "Signatu SDK"), " is written in ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Isomorphic_JavaScript"
  }, "Isomorphic Javascript"), ",\nwhich means that you can use it in your NodeJS server as well as in the browser."), "\n", React.createElement(_components.h2, {
    id: "oauth",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oauth",
    "aria-label": "oauth permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "OAuth"), "\n", React.createElement(_components.p, null, "Access to the Signatu APIs are authorized using OAuth Bearer tokens. Your NodeJS app can\nuse ", React.createElement(_components.a, {
    href: "/app/account#tokens"
  }, "Client Credentials"), " to create access tokens. We provide a\nconvenient ", React.createElement(_components.code, null, "getToken()"), " function that will manage the provisioning for you."), "\n", React.createElement(_components.p, null, "You need to set the following environment variables:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "SIGNATU_CLIENT_ID"), " - the client ID."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "SIGNATU_CLIENT_SECRET"), " - the client secret."), "\n"), "\n", React.createElement(_components.p, null, "You can create new clients in ", React.createElement(_components.a, {
    href: "/app/account#tokens"
  }, "Access Tokens"), " under your account."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "IMPORTANT"), " - do not put any of these keys in your code (see ", React.createElement(_components.a, {
    href: "../security"
  }, "Security"), ")."), "\n", React.createElement(_components.h3, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const getToken = require('@signatu/sdk').oauth.getToken\nconst scope = 'consent policy' // list of scopes separated by space\nconst token = await getToken(scope)\n\n/*\n * { access_token: '...',\n *   expires_in: 3600,\n *   scope: 'consent policy DEFAULT',\n *   refresh_token: '...',\n *   token_type: 'Bearer' }\n */\n")), "\n", React.createElement(_components.h2, {
    id: "express",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#express",
    "aria-label": "express permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Express"), "\n", React.createElement(_components.p, null, "We provide an example ", React.createElement(_components.a, {
    href: "https://expressjs.com/"
  }, "Express"), " proxy."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note"), " - this code is an example and is not production ready."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "const proxy = require('express-http-proxy')\nconst express = require('express')\nconst getToken = require('@signatu/sdk').oauth.getToken\n\nconst app = express()\n\nconst endPoint = 'https://api.signatu.com'\n\nconst scope = 'consent'\nconst port = 3600\n\nconst SIGNATU_API_KEY = process.env.SIGNATU_API_KEY\nif (!SIGNATU_API_KEY) {\n    throw new Error(`Must set SIGNATU_API_KEY`)\n}\n\napp.use(\n    proxy(endPoint, {\n        proxyReqOptDecorator: async (proxyReqOpts, srcReq) => {\n            const token = await getToken(scope)\n            if (token) {\n                proxyReqOpts.headers['Authorization'] = `Bearer ${token.access_token}`\n                /** x-api-key is required for /v0 of the Consent API */\n                proxyReqOpts.headers['x-api-key'] = SIGNATU_API_KEY\n            }\n            return proxyReqOpts\n        },\n    })\n)\n\n/** @note this is not production ready - just an example for how to proxy requests */\napp.listen(port, () => console.log(`Example Signatu Proxy listening on port ${port}!`))\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
